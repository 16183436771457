import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    cart: {},
    payment: {},
    cartDeleted: false,
    loading: false,
    errorMsg: '',
    errors: {}
};

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        SetSuccessCheckout: (state, action) => {
            state.cart = action.payload;

            localStorage.setItem('cart', JSON.stringify(action.payload));
        },
        DeleteCart: (state) => {
            state.cartDeleted = true;

            localStorage.removeItem('cart');
        },
        SetSuccessPayment: (state, action) => {
            state.payment = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload
        },
    },
});

export const {
    loading,
    SetSuccessCheckout,
    SetSuccessPayment,
    DeleteCart,
    clearErrors,
    getErrors
} = checkoutSlice.actions;
export default checkoutSlice.reducer;
