import {HSeparator} from "@/components/separator";
import {Modal} from "@/components/modal";
import React from "react";
import {FormatCurrency} from "@/routes/root";

interface Ticket {
    name: string;
}

interface PaymentDetailModalProps {
    showSubtotal: boolean;
    setShowSubtotal: (show: boolean) => void;
    ticket: Ticket;
    paymentFee: number;
    ticketTotal: number;
    quantity: number;
    adminFee: number;
    subtotal: number;
}

export const PaymentDetailModal: React.FC<PaymentDetailModalProps> = ({
                                                                          showSubtotal,
                                                                          setShowSubtotal,
                                                                          ticket,
                                                                          paymentFee,
                                                                          ticketTotal,
                                                                          subtotal,
                                                                          adminFee,
                                                                          quantity
                                                                      }) => {
    return (
        <>
            <Modal
                show={showSubtotal}
                onRequestClose={() => {
                    setShowSubtotal(false)
                }}
                title='Detail'
                body={
                    <div className={'flex flex-col gap-2'}>
                        <div className={'flex justify-between'}>
                            <p className='text-base font-medium'>{ticket?.name} <span
                                className={'text-blue-500'}>({quantity}x)</span></p>
                            <p className='text-base font-medium'><FormatCurrency amount={subtotal}/></p>
                        </div>
                        <div className={'flex justify-between'}>
                            <p className='text-base font-medium'>Biaya admin</p>
                            <p className='text-base font-medium'><FormatCurrency amount={adminFee}/></p>
                        </div>
                        <div className={'flex justify-between'}>
                            <p className='text-base font-medium'>Biaya transaksi <span
                                className={'text-blue-500'}></span></p>
                            <p className='text-base font-medium'><FormatCurrency amount={paymentFee}/></p>
                        </div>
                        <HSeparator/>
                        <div className={'flex justify-between'}>
                            <p className='text-base font-medium'>Total</p>
                            <p className='text-base font-medium text-red-500'><FormatCurrency amount={ticketTotal}/></p>
                        </div>
                    </div>
                }
            />
        </>
    )
}
