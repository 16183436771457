import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    selectedTicket: {},
    issuedTicket: {},
    loading: false,
    errorMsg: '',
    errors: {}
};

const ticketSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        SelectTicket: (state, action) => {
            state.selectedTicket = action.payload;
        },
        SetIssuedTickets: (state, action) => {
            state.issuedTicket = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const {loading, SelectTicket, SetIssuedTickets, getErrors} = ticketSlice.actions;
export default ticketSlice.reducer;
