import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {loading, getErrors, SetSuccessCheckout, SetSuccessPayment, DeleteCart} from "@/reducers/checkout";

export const CheckoutCart = (eventId: string, ticketId: string, quantity: number): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/guests/events/' + eventId + '/tickets/' + ticketId + '/checkout', {
            quantity: quantity
        }, {
            headers: headers()
        }).then((response) => {
            dispatch(SetSuccessCheckout(response.data.data));

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });

    return response;
};

export const CheckoutPayment = (eventId: string, cartId: string, formData: any): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/guests/events/' + eventId + '/carts/' + cartId + '/pay', formData, {
            headers: headers()
        }).then((response) => {
            dispatch(SetSuccessPayment(response.data.data));

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });

    return response;
};

export const CancelCheckout = (eventId: string, cartId: string): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .delete(process.env.REACT_APP_BASE_URL + '/guests/events/' + eventId + '/carts/' + cartId, {
            headers: headers()
        }).then((response) => {
            dispatch(DeleteCart());
        }).catch((error: any) => {
            const {errors, message, code} = error.response.data.error;

            if (code === 404) {
                dispatch(DeleteCart());
            }

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};
