import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
    SetIssuedTickets, loading,
    getErrors
} from "@/reducers/tickets";

export const GetIssuedTickets = (slug?: string): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/guests/tickets/' + slug + '/issued', {
            headers: headers()
        }).then((response) => {
            dispatch(SetIssuedTickets(response.data.data));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};
