import {createSlice} from '@reduxjs/toolkit';

interface Identity {
    holder_name: string;
    number: string;
    address: string;
    phone: string;
}

interface Document {
    type: string;
    file: File;
    identity?: Identity;
    data?: object;
}

interface Channel {
    id: number;
    code: string;
    name: string;
    checked: boolean;
    icon: string;
}

export interface EventsInterface {
    slug?: string | null,
    code?: string | null,
    banner?: {
        fallback_url?: string | null,
        srcset?: string | null,
    },
    title?: string | null,
    description?: string | null,
    terms?: string | null,
    type?: string | null,
    status?: string | null,
    start_at?: string | null,
    end_at?: string | null,
    tickets: any,
    channels: Channel[],
    customer_data: any,
    documents: Document[],
    location?: {
        name?: string | null,
        url?: string | null,
        type?: string | null
    }
}

const initialEvents: EventsInterface = {
    slug: null,
    code: null,
    banner: {
        fallback_url: null,
        srcset: null,
    },
    title: null,
    description: null,
    terms: null,
    type: null,
    status: null,
    start_at: null,
    tickets: [],
    channels: [],
    customer_data: [],
    documents: [],
    location: {
        name: null,
        url: null,
        type: null
    }
}

const initialState = {
    events: [],
    tickets: [],
    channels: [],
    customer_data: [],
    documents: [
        {
            type: 'identity'
        },
        {
            type: 'permit'
        }
    ],
    event: initialEvents,
    loading: false,
    onPublishClicked: '',
    onEventCreated: '',
    onSuccessSubmit: false,
    onEventDeleted: false,
    successText: '',
    errorMsg: '',
    errors: {}
};

const eventSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        events: (state, action) => {
            state.events = action.payload;
        },
        setTickets: (state, action) => {
            state.tickets = action.payload;
        },
        setChannels: (state, action) => {
            state.channels = action.payload;
        },
        setCustomerData: (state, action) => {
            state.customer_data = action.payload;
        },

        updateChannelChecked: (state: any, action: { payload: { id: number, checked: boolean } }) => {
            const {id, checked} = action.payload;
            state.channels = state.channels.map((channel: any) =>
                channel.id === id ? {...channel, checked} : channel
            );
        },

        updateCustomerDataChecked: (state: any, action: { payload: { id: number, checked: boolean } }) => {
            const {id, checked} = action.payload;
            state.customer_data = state.customer_data.map((customerData: any) =>
                customerData.id === id ? {...customerData, checked} : customerData
            );
        },

        updateDocument: (state: any, action: { payload: any }) => {
            const {type} = action.payload;

            state.documents = state.documents.filter((doc: any) => doc.type !== type);
            state.documents.push(action.payload);
        },

        setDocument: (state: any, action: { payload: any }) => {
            state.documents = action.payload;
        },

        updateOrAddTicket: (state: any, action: { payload: any }) => {
            const updatedTicket = action.payload;

            const existingTicketIndex = state.tickets.findIndex((ticket: any) => ticket.id === updatedTicket.id);

            if (existingTicketIndex !== -1) {
                state.tickets[existingTicketIndex] = {
                    ...state.tickets[existingTicketIndex],
                    ...updatedTicket
                };
            } else {
                const existingIds = state.tickets.map((ticket: any) => ticket.id);
                state.tickets.push({...updatedTicket, id: generateRandomId(existingIds)});
            }
        },

        deleteTicket: (state: any, action: { payload: any }) => {
            state.tickets = state.tickets.filter((ticket: any) => ticket.id !== action.payload)
        },

        setEvent: (state, action) => {
            state.event = action.payload;
        },
        setSuccessText: (state, action) => {
            state.successText = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        },
        setOnPublishClicked: (state, action) => {
            state.onPublishClicked = action.payload;
        },
        setOnEventCreated: (state, action) => {
            state.onEventCreated = action.payload;
        },
        setOnEventDeleted: (state) => {
            state.onEventDeleted = true;
        },
        setOnSuccessSubmit: (state, action) => {
            state.onSuccessSubmit = action.payload;
        },
    },
});

export const {
    loading,
    events,
    setEvent,
    setOnEventCreated,
    setOnEventDeleted,
    updateDocument,
    setDocument,
    updateChannelChecked,
    updateCustomerDataChecked,
    updateOrAddTicket,
    deleteTicket,
    clearErrors,
    setOnPublishClicked,
    setTickets,
    setChannels,
    setCustomerData,
    setOnSuccessSubmit,
    setSuccessText,
    getErrors
} = eventSlice.actions;
export default eventSlice.reducer;

const generateRandomId = (existingIds: string[]) => {
    let id;
    do {
        id = Math.floor(Math.random() * 100000).toString().padStart(8, '0');
    } while (existingIds.includes(id));
    return id;
};
