import {Modal} from '@/components/modal';
import {TicketCard} from "@/routes/guest/event/_ticket/ticketcard";
import React from "react";

export interface TicketListModalProps {
    tickets: any,
    currentTicket?: string;
    show: boolean;
    flowStep?: number;
    onRequestClose?: () => void;
    onTicketClicked?: (id: string) => void;
}

const bgColors: any = [
    'bg-teal-700',
    'bg-yellow-700',
    'bg-sky-700',
    'bg-violet-700',
    'bg-indigo-700',
    'bg-orange-700',
    'bg-lime-700',
    'bg-emerald-700',
    'bg-green-700',
    'bg-fuchsia-700',
    'bg-pink-700',
    'bg-rose-700'
];

const textColors: any = [
    'text-teal-700',
    'text-yellow-500',
    'text-sky-500',
    'text-violet-700',
    'text-indigo-500',
    'text-orange-700',
    'text-lime-700',
    'text-emerald-700',
    'text-green-700',
    'text-fuchsia-700',
    'text-pink-700',
    'text-rose-700'
];

export const TicketListModal: React.FC<TicketListModalProps> = ({
                                                                    tickets = [],
                                                                    currentTicket,
                                                                    show,
                                                                    onRequestClose,
                                                                    onTicketClicked,
                                                                }) => {
    return (
        <Modal
            show={show}
            onRequestClose={onRequestClose}
            title='Pilih tiket'
            width='large'
            body={
                <div>
                    {tickets.length === 0 && <div className={'text-center'}>
                        <p>Belum ada tiket</p>
                    </div>}
                    <div className='grid md:grid-cols-2 gap-4'>
                        {
                            tickets.map((ticket: any, key: string) => (
                                <TicketCard
                                    bgColor={bgColors[key]}
                                    textColor={textColors[key]}
                                    key={key}
                                    ticket={ticket}
                                    isCurrentTicket={currentTicket === ticket.id}
                                    onTicketClicked={onTicketClicked}
                                />
                            ))
                        }
                    </div>
                </div>
            }
        />
    );
};
