import React, {useState, useEffect} from 'react';
import moment from 'moment';

const Countdown = ({expiredAt, onExpire}) => {
    const [timeLeft, setTimeLeft] = useState(
        expiredAt ? calculateTimeLeft(expiredAt) : {minutes: '15', seconds: '00'}
    );

    useEffect(() => {
        if (!expiredAt) return;
        
        const timer = setInterval(() => {
            const remainingTime = calculateTimeLeft(expiredAt);
            setTimeLeft(remainingTime);

            if (
                remainingTime.days <= 0 &&
                remainingTime.hours <= 0 &&
                remainingTime.minutes <= 0 &&
                remainingTime.seconds <= 0
            ) {
                clearInterval(timer);
                if (onExpire) {
                    onExpire();
                }
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [expiredAt, onExpire]);

    return <>{formatTime(timeLeft.minutes)}:{formatTime(timeLeft.seconds)}</>;
};

const calculateTimeLeft = (expiredAt) => {
    const now = moment();
    const expiration = moment(expiredAt);
    const duration = moment.duration(expiration.diff(now));

    return {
        days: Math.max(0, duration.days()),
        hours: Math.max(0, duration.hours()),
        minutes: Math.max(0, duration.minutes()),
        seconds: Math.max(0, duration.seconds()),
    };
};

const formatTime = (unit) => String(unit).padStart(2, '0');

export default Countdown;
