import React from "react";

export function Root() {
    return <p className='text-2xl font-medium'>Tokoevent CRA WIP</p>;
}

export interface FormatCurrencyProps
    extends React.HTMLAttributes<HTMLDivElement> {
    amount: number;
    currency?: string;
}

export const FormatCurrency: React.FC<FormatCurrencyProps> = ({currency = 'IDR', amount}) => {
    return <>
        {new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: 0
        }).format(amount).replace("IDR", "Rp")}
    </>
}
