import {cn} from '@/lib/utils';
import {Slot} from '@radix-ui/react-slot';
import React, {useState} from 'react';

import styles from './Input.module.css';
import {EyeIcon} from "@heroicons/react/24/solid";
import {EyeSlashIcon} from "primereact/icons/eyeslash";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    leftDeco?: React.ReactNode;
    rightDeco?: React.ReactNode;
    leftIcon?: React.ReactElement;
    rightIcon?: React.ReactElement;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            type,
            readOnly,
            disabled,
            leftDeco,
            rightDeco,
            leftIcon,
            rightIcon,
            className,
            onChange,
            ...props
        },
        ref
    ) => {
        const [value, setValue] = useState(props.value || "");
        const [showPassword, setShowPassword] = useState(type);
        const isPassword: boolean = type === "password";

        // Helper function for phone validation
        const isValidPhone = (input: string) => /^[1-9]\d{0,11}$/.test(input) || input === '';

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            let input = event.target.value;
            // Apply phone filtering only if type is "phone"
            if (type === "phone" && !isValidPhone(input)) {
                return; // If input is invalid, stop further processing
            }

            setValue(input);

            // Create a custom event to pass the filtered value
            const newEvent = {
                ...event,
                target: {...event.target, value: input}
            };

            // Call the original onChange passed in props
            if (onChange) {
                onChange(newEvent);
            }
        };

        const decoTextStyles = cn(
            'shrink-0 border-neutral-50 flex-grow-0 py-2 text-xs font-normal p-2',
            disabled
                ? 'text-neutral-60 bg-neutral-40'
                : 'text-neutral-80 bg-neutral-10'
        );

        const iconStyles =
            'absolute top-1/2 h-[14px] w-[14px] -translate-y-1/2 text-neutral-90';

        if (isPassword) {
            if (showPassword === "password") {
                rightIcon = <EyeIcon onClick={() => {
                    setShowPassword("text")
                }
                }/>;
            } else {
                rightIcon = <EyeSlashIcon onClick={() => {
                    setShowPassword("password")
                }
                }/>;
            }

            type = showPassword;
        }

        return (
            <div
                className={cn(
                    'flex w-full [&>*]:rounded-md [&>*]:border-[1px]',
                    styles.inputBorders,
                    className
                )}
            >
                {leftDeco ? (
                    <div className={decoTextStyles}>{leftDeco}</div>
                ) : undefined}

                <div
                    className={cn(
                        styles.inputWrapper,
                        'relative shrink grow bg-neutral-10',
                        disabled ? 'border-neutral-50 bg-neutral-40 text-neutral-60' : ''
                    )}
                >
                    {leftIcon ? (
                        <Slot
                            className={cn(
                                iconStyles,
                                'left-[12px]',
                                leftIcon.props.className
                            )}
                        >
                            {leftIcon}
                        </Slot>
                    ) : undefined}

                    <input
                        type={type}
                        className={cn(
                            'block w-full rounded-md bg-transparent py-2 text-xs font-normal placeholder:text-neutral-70',
                            leftIcon ? 'pl-[34px]' : 'pl-3',
                            rightIcon ? 'pr-[34px]' : 'pr-3'
                        )}
                        onChange={handleChange}
                        value={value}
                        ref={ref}
                        readOnly={readOnly}
                        disabled={disabled}
                        {...props}
                    />

                    {rightIcon ? (
                        <Slot
                            className={cn(
                                iconStyles,
                                'right-[12px]',
                                rightIcon.props.className
                            )}
                        >
                            {rightIcon}
                        </Slot>
                    ) : undefined}
                </div>

                {rightDeco ? (
                    <div className={decoTextStyles}>{rightDeco}</div>
                ) : undefined}
            </div>
        );
    }
);
Input.displayName = 'Input';
