import {ChevronRightIcon} from "@heroicons/react/24/solid";
import {HSeparator} from "@/components/separator";
import {Modal} from "@/components/modal";
import React from "react";


interface PaymentMethodModalProps {
    showPaymentMethods: boolean;
    setShowPaymentMethods: (show: boolean) => void;
    payment_channels: PaymentChannel[];
    setSelectedPaymentMethod: (method: PaymentChannel) => void;
}

interface PaymentChannel {
    code: string;
    name: string;
    settings: any;
}

export const PaymentMethodLabel: React.FC<{ label: string, code: string }> = ({label, code}) => {
    return (
        <div className={'flex gap-2'}>
            <img className={'w-16'} src={`/assets/channels/${code.toLowerCase()}.png`} alt={label}/>
            <p className='text-sm font-semibold my-auto'>{label}</p>
        </div>
    );
};

export const PaymentMethodModal: React.FC<PaymentMethodModalProps> = ({
                                                                          showPaymentMethods,
                                                                          setShowPaymentMethods,
                                                                          payment_channels,
                                                                          setSelectedPaymentMethod,
                                                                          ...props
                                                                      }) => {
    return (
        <>
            <Modal
                {...props}
                show={showPaymentMethods}
                onRequestClose={() => {
                    setShowPaymentMethods(false)
                }}
                title='Metode pembayaran'
                body={
                    <div className={'flex flex-col gap-2'}>
                        {payment_channels?.map((channel, index) => (
                            <>
                                <div className={'flex justify-between gap-2 p-2 cursor-pointer'} key={index}
                                     onClick={() => {
                                         setSelectedPaymentMethod({
                                             code: channel.code,
                                             name: channel.name,
                                             settings: channel.settings
                                         })
                                         setShowPaymentMethods(false)
                                     }}>
                                    <PaymentMethodLabel label={channel.name} code={channel.code}/>
                                    <ChevronRightIcon className='h-[20px] w-[20px] text-black my-auto'/>
                                </div>
                                <HSeparator/>
                            </>
                        ))
                        }
                    </div>
                }
            />
        </>
    )
}
