import React from "react";
import {Button} from "@/components/button";
import {ChartPieIcon} from "@heroicons/react/24/solid";

const NotFound = () => {
    return (
        <div className='flex flex-col items-center gap-6 p-6'>
            <img
                src='/assets/empty.png'
                width={300}
                height={247}
                alt=''
            />
            <div className='flex flex-col items-center gap-3'>
                <p className='text-2xl font-medium'>Halaman tidak ditemukan</p>
                <p className='text-center text-base text-neutral-70'>
                    Saat ini belum ada halaman yang kamu minta
                </p>
            </div>
            <Button element='a' href='https://tokoevent.id' leftIcon={<ChartPieIcon/>}>
                Kembali ke dashboard
            </Button>
        </div>
    );
};

export default NotFound;
