import {Breadcrumb, BreadcrumbRouteTree} from '@/components/breadcrumb';
import {cn} from '@/lib/utils';
import {ArrowRightStartOnRectangleIcon} from '@heroicons/react/24/outline';
import {useEffect, useState} from 'react';
import {Navigate, Outlet, useLocation, useParams} from 'react-router-dom';
import styles from './OrgLayout.module.css';
import {HomeIcon} from "@heroicons/react/24/solid";

const routeTree: BreadcrumbRouteTree = {
    event: {
        name: 'Event',
        children: {
            '*': {
                name: 'Detail Event',
                children: {
                    '*': {
                        name: 'Checkout',
                    },
                },
            },
        },
    },
};

export function GuestLayout() {
    const location = useLocation();
    const {slug} = useParams();
    const [logoutWarnModal, setLogoutWarnModal] = useState(false);

    const pathArray = location.pathname.substring(0).split('/').slice(1);
    const section = routeTree[pathArray[0]];

    const [nav, setNav] = useState(false);
    useEffect(() => setNav(false), [location]);

    // useEffect(() => {
    //   if (!authToken || isLoggedOut) {
    //     window.location.href = '/auth/login';
    //   }
    // }, [authToken, isLoggedOut]);

    // if(!user?.isVerified) {
    //   window.location.href = "/auth/complete";
    // }

    const coverStyle = cn(
        styles.coverWrapper,
        nav ? `${styles.isOpen} pointer-events-auto` : 'pointer-events-none',
        'fixed h-screen w-screen lg:hidden'
    );

    /*<div className='relative h-[calc(100dvh)] w-screen'>
      <Throbber className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2' />
    </div>*/

    return (<>
            <div className='h-[calc(100dvh)] w-screen lg:flex'>
                <div className={coverStyle} onClick={() => setNav(false)}/>
                <div className='relative flex flex-shrink flex-grow flex-col overflow-x-hidden'>
                    {/* Top Nav */}
                    <div
                        className='absolute left-0 right-0 top-0 z-[9] flex items-center bg-neutral-10/90 p-4 shadow-sm backdrop-blur-md lg:p-6'>
                        <div className={'flex-grow'}>
                            <img
                                src='/logo.png'
                                alt='Logo'
                                className='w-16 lg:w-20 object-contain'
                            />
                        </div>
                        <span onClick={() => {
                            window.location.href = '/' + slug + '/retrieve-ticket'
                        }} className={'underline cursor-pointer'}>Tiket hilang?</span>
                    </div>

                    <div
                        className='flex h-[calc(100dvh)] flex-col gap-4 overflow-hidden overflow-y-auto md:mx-24 no-scrollbar p-4 pt-[calc(60px+16px)] lg:gap-6 lg:p-6 lg:pt-[calc(76px+28px)]'>
                        {pathArray.length > 1 ? (
                            <Breadcrumb
                                routeTree={routeTree}
                                basePath='/'
                                pathArray={pathArray}
                            />
                        ) : undefined}

                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
    );
}
