import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
    loading,
    getErrors,
    events,
    setEvent,
    setTickets,
    setChannels, setCustomerData, setSuccessText
} from "@/reducers/events";

export const GetEvents = (query?: string | null): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/org/events?filter[search]=' + query, {
            headers: headers()
        }).then((response) => {
            dispatch(events(response.data.data));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const ShowEvent = (slug?: string): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/guests/events/' + slug, {
            headers: headers()
        }).then((response) => {
            dispatch(setEvent(response.data.data));
            dispatch(setTickets(response.data.data.tickets));
            dispatch(setChannels(response.data.data.channels));
            dispatch(setCustomerData(response.data.data.customer_data));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            if (error.response.status === 404) {
                window.location.href = "event/error";
            }

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const RetrieveTicketService = (eventId?: string, email?: string): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/guests/events/' + eventId + '/retrieve-ticket', {
                email: email
            },
            {
                headers: headers()
            }).then((response) => {
            dispatch(setSuccessText(response.data.message));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};
