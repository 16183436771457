import React, {useEffect, useState} from 'react';
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {ArrowLeftIcon, PaperAirplaneIcon} from "@heroicons/react/24/solid";
import {Button} from "@/components/button";
import {RetrieveTicketService, ShowEvent} from "@/services/events";
import {FormLabel} from "@/components/form/formlabel";
import {Input} from "@/components/form/input";
import {getErrors} from "@/reducers/events";

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
    label?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({label, children}) => {
    return (
        <div className='flex flex-col gap-4 mx-4 md:mx-24'>
            <h1 className='text-2xl font-semibold'>{label}</h1>
            {children}
        </div>
    );
};

export function RetrieveTicket() {
    const {slug} = useParams();
    const [email, setEmail] = useState("");
    const dispatch: AppDispatch = useDispatch();
    const {event, successText, errors} = useSelector((state: any) => state.event);

    const submit = () => {
        dispatch(getErrors({}));
        dispatch(RetrieveTicketService(event.id, email))
    }

    useEffect(() => {
        dispatch(ShowEvent(slug))
    }, [dispatch, slug]);

    return (
        <>
            <Section>
                <div>
                    <div className="text-900 text-2xl font-semibold">🎫 Tiket <span
                        className={'underline'}>{event.title}</span> kamu hilang?
                    </div>
                    <p className={"text-400 mb-4"}>Tenang, kamu bisa ambil tiketmu kembali disini!</p>
                </div>
                <FormLabel label={"Email"} errorText={errors?.email} successText={successText}
                           helperText={'Gunakan email yang kamu gunakan saat membeli tiket'} required>
                    <Input type={'text'} onChange={(e) => setEmail(e.target.value)} value={email} required/>
                </FormLabel>
                <div className={'flex justify-center gap-2'}>
                    <Button className={'mt-2'}
                            variant={'neutral'}
                            element={'a'}
                            href={'/' + event.slug}
                            leftIcon={<ArrowLeftIcon/>}>Beli Tiket</Button>
                    <Button className={'mt-2'}
                            onClick={submit}
                            rightIcon={<PaperAirplaneIcon/>}>Kirim Tiket Ke Email Saya</Button>
                </div>
            </Section>
        </>
    );
}
