import {configureStore} from '@reduxjs/toolkit'
// import passwordReducer from "@/reducers/passwords";
import eventReducer from "@/reducers/events";
import ticketReducer from "@/reducers/tickets";
import checkoutReducer from "@/reducers/checkout";

const store = configureStore({
    reducer: {
        // auth: authReducer,
        // passwords: passwordReducer,
        event: eventReducer,
        tickets: ticketReducer,
        checkout: checkoutReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
