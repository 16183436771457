import {Button} from '@/components/button';
import {
    ArrowLeftIcon,
    CheckBadgeIcon, ChevronRightIcon, ChevronUpIcon, ExclamationTriangleIcon,
    TicketIcon, XMarkIcon,
} from '@heroicons/react/24/solid';
import React, {useEffect, useState} from 'react';
import {ShowEvent} from "@/services/events";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Modal} from "@/components/modal";
import {FormatCurrency} from "@/routes/root";
import {FormLabel} from "@/components/form/formlabel";
import {Input} from "@/components/form/input";
import {Select, SelectGroup, SelectValue} from "@radix-ui/react-select";
import {SelectContent, SelectItem, SelectTrigger} from "@/components/form/select";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {CancelCheckout, CheckoutPayment} from "@/services/checkout";
import {clearErrors} from "@/reducers/checkout";
import Countdown from "@/components/countdown";
import {PaymentMethodLabel, PaymentMethodModal} from "@/routes/guest/event/_payment/paymentmethodmodal";
import {PaymentDetailModal} from "@/routes/guest/event/_payment/paymentdetailmodal";

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
    label?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({label, children}) => {
    return (
        <div className='flex flex-col gap-4'>
            <h1 className='text-center text-xl font-semibold'>{label}</h1>
            {children}
        </div>
    );
};

let defaultCart = {
    id: '',
    ticket: {
        name: ''
    },
    event_id: '',
    price: 0,
    quantity: 0,
    admin_fee: 0,
    subtotal: 0,
    currency_code: '',
    expired_at: ''
};

export function Checkout() {
    const [cancelWarnModal, setCancelWarnModal] = useState(false)
    const [showSubtotal, setShowSubtotal] = useState(false)
    const [cart, setCart] = useState(defaultCart)
    const [showPaymentMethods, setShowPaymentMethods] = useState(false)
    const [ticketTotal, setTicketTotal] = useState(0)
    const [paymentFee, setPaymentFee] = useState(0)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
        name: '',
        code: '',
        settings: {
            type: '',
            amount: 0,
        }
    })
    const [formData, setFormData] = useState({})
    const {slug} = useParams();
    const dispatch: AppDispatch = useDispatch();
    const {event} = useSelector((state: any) => state.event);
    const {loading, errors, payment, cartDeleted} = useSelector((state: any) => state.checkout);

    const submitPayment = () => {
        dispatch(CheckoutPayment(event.id, cart.id, {...formData, payment_method: selectedPaymentMethod.code}))
    }

    const cartExpired = () => {
        dispatch(CancelCheckout(event.id, cart.id))
    }

    const setData = (value: any, element: string) => {
        setFormData({...formData, [element]: value})

        dispatch(clearErrors());
    }

    useEffect(() => {
        if (cartDeleted) {
            window.location.href = `/${slug}`;
        }
    }, [cartDeleted]);

    useEffect(() => {
        dispatch(ShowEvent(slug))
    }, [dispatch, slug]);

    useEffect(() => {
        if (payment.redirect_uri) {
            localStorage.removeItem('cart');
            window.location.href = payment.redirect_uri;
        }
    }, [payment]);

    useEffect(() => {
        // @ts-ignore
        let cart = JSON.parse(localStorage.getItem('cart'));

        if (!cart) {
            window.location.href = `/${slug}`;
        }

        setCart(cart)

        let settings = selectedPaymentMethod.settings;
        let total = cart.subtotal + cart.admin_fee;

        let amount = settings?.type === 'nominal' ? settings?.amount : settings.amount * total;
        setPaymentFee(amount)
        setTicketTotal(total + amount)
    }, [selectedPaymentMethod]);

    return (
        <>
            <Modal
                show={cancelWarnModal}
                onRequestClose={() => setCancelWarnModal(false)}
                width='fit'
                title='Peringatan!'
                body={
                    <>
                        Apakah yakin kamu ingin membatalkan pesanan?
                    </>
                }
                footer={
                    <div className='flex justify-end gap-3'>
                        <Button
                            variant='neutral'
                            onClick={() => setCancelWarnModal(false)}
                            leftIcon={<XMarkIcon/>}
                        >
                            Tidak
                        </Button>
                        <Button
                            loading={loading}
                            variant='danger'
                            onClick={cartExpired}
                            leftIcon={<ExclamationTriangleIcon/>}
                        >
                            Iya
                        </Button>
                    </div>
                }
            />

            <PaymentDetailModal
                paymentFee={paymentFee}
                showSubtotal={showSubtotal}
                setShowSubtotal={setShowSubtotal}
                ticket={cart.ticket}
                ticketTotal={ticketTotal}
                quantity={cart.quantity}
                subtotal={cart.subtotal}
                adminFee={cart.admin_fee}
            />

            <PaymentMethodModal
                showPaymentMethods={showPaymentMethods}
                setShowPaymentMethods={setShowPaymentMethods}
                payment_channels={event.payment_channels}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
            />

            <div className={'mt-8 md:mx-24 lg:mx-48'}>
                <Section label='Data pembeli'>
                    <div className='flex flex-col gap-6'>
                        <div className='shrink-0 basis-[429px] flex-col gap-5'>
                            <div className={'rounded-md border-[1px] border-neutral-40 p-5 mb-20'}>
                                <div className='flex flex-col gap-2'>
                                    <FormLabel label='Tiket terpilih'
                                               errorText={errors.checkout}>
                                        <div
                                            className='flex gap-3 rounded-md border-[1px] border-neutral-40 p-3 cursor-pointer'>
                                            <TicketIcon className='h-[20px] w-[20px] text-primary'/>
                                            <p className='text-sm font-medium'>{cart.ticket?.name} <span
                                                className={'text-blue-500'}>({cart.quantity}x)</span></p>
                                        </div>
                                    </FormLabel>

                                    {event?.customer_fields?.map((field: any, index: string) => (
                                        <FormLabel key={index} label={field.label} errorText={errors[field.name]}
                                                   required>
                                            <Input type={field.name} name={field.name}
                                                   leftDeco={field.name === 'phone' ? event.organizer?.country?.phone_code : ''}
                                                   onChange={(e: any) => setData(e.target.value, field.name)} required/>
                                        </FormLabel>
                                    ))}

                                    <FormLabel label='Bagaimana saya menerima tiket?'
                                               errorText={errors.notification_channel} required>
                                        <Select
                                            onValueChange={(e: any) => setData(e, 'notification_channel')}>
                                            <SelectTrigger>
                                                <SelectValue defaultValue='email'/>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    <SelectItem value='email'>Kirim tiket saya melalui
                                                        email</SelectItem>
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </FormLabel>

                                    <FormLabel label='Metode pembayaran' errorText={errors.payment_method} required>
                                        <div
                                            className='flex justify-between gap-3 rounded-md border-[1px] border-neutral-40 p-3 cursor-pointer md:mb-4'>
                                            {selectedPaymentMethod.code ?
                                                <PaymentMethodLabel label={selectedPaymentMethod.name}
                                                                    code={selectedPaymentMethod.code}/> :
                                                <p className='text-sm font-medium text-gray-400 my-auto'>{'Pilih metode pembayaran'}</p>}
                                            {selectedPaymentMethod.code ?
                                                <Button look={'outline'} variant={'neutral'} onClick={() => {
                                                    setShowPaymentMethods(true)
                                                }} rightIcon={<ArrowPathIcon/>}>
                                                    Ganti
                                                </Button> : <Button onClick={() => {
                                                    setShowPaymentMethods(true)
                                                }} rightIcon={<ChevronRightIcon/>}>
                                                    Pilih
                                                </Button>}
                                        </div>
                                    </FormLabel>
                                </div>
                                <div
                                    className={'absolute flex md:flex-col justify-between left-0 right-0 bottom-0 z-[5] p-4 bg-white md:static md:p-0'}>
                                    <div className='md:ml-auto gap-2'>
                                        <p className='mr-auto text-base font-medium'>Subtotal</p>
                                        <div className={'flex'}>
                                            <p className='text-base font-medium text-danger'><FormatCurrency
                                                amount={ticketTotal}/></p>
                                            <ChevronUpIcon className='ml-2 h-[16px] w-[16px] self-center'
                                                           onClick={() => {
                                                               setShowSubtotal(true)
                                                           }}/>
                                        </div>
                                    </div>
                                    <div className={'flex gap-2'}>
                                        <Button variant={'danger'} className={'mt-2'} onClick={() => {
                                            setCancelWarnModal(true)
                                        }}
                                                leftIcon={<ArrowLeftIcon/>}>
                                            Batal
                                        </Button>
                                        <Button loading={loading} className={'md:w-full mt-2'}
                                                leftIcon={<CheckBadgeIcon/>}
                                                onClick={submitPayment}>
                                            Bayar (<Countdown expiredAt={cart.expired_at}
                                                              onExpire={cartExpired}/>)
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>
            </div>
        </>
    );
}
